<template>
  <div class="grid">
    <div class="col-12">
      <div v-if="auth.hasRoleConsignatariaAdmin() || auth.hasRoleAverbar()">
        <consignacao-form
          :tipo="tipo"
          :desativarBotao="false"></consignacao-form>
      </div>
      <div v-else>
        <Panel header="Averbação" class="mt-3">
          <h6>
            {{ auth.msgPermissao() }}
          </h6>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import ConsignacaoForm from '@/components/shared/consignacao/consignacaoForm.vue'
import { consignatariaStore } from '@/stores/consignataria'
import auth from '@/modules/auth'

export default {
  components: {
    'consignacao-form': ConsignacaoForm,
  },
  setup() {
    const storeConsignataria = consignatariaStore()
    return { storeConsignataria }
  },

  data() {
    return {
      auth,
    }
  },

  computed: {
    tipo() {
      return 'Averbar'
    },
  },
}
</script>

<style scoped>
h6 {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #c82333;
}
</style>
